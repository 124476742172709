<!--
 * @Description:财务管理系统 开票记录管理 重新开票 reInvoiceRecord
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-06-03 14:35:33
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainbody">
    <!-- 上半部查询 -->
    <el-row class="topSelectTwoLine">
      <el-form :model="searchForm"
               :inline="true">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="申请时间">
              <el-date-picker v-model="time"
                              type="daterange"
                              align="right"
                              unlink-panels
                              value-format="yyyy-MM-dd"
                              range-separator="-"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="开票类型">
              <el-select clearable
                         filterable
                         v-model="searchForm.invoiceType"
                         placeholder="请选择">
                <el-option v-for="item in typeList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="开票人账号">
              <el-input v-model="searchForm.applyUser"
                        placeholder="请输入">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="申请状态">
              <el-select clearable
                         filterable
                         v-model="searchForm.status"
                         placeholder="请选择">
                <el-option v-for="item in statusList"
                           :key="item.code"
                           :label="item.name"
                           :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>
    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <span>发票重开</span>
      </el-row>
      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList">
          <template slot="operating">
            <el-table-column label="操作"
                             class-name='class-name'>
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleClickDetails(scope.row)">详情</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 详情显示 -->
    <el-dialog class="dialog"
               title="发票详情"
               append-to-body
               top:10vh
               :visible.sync="detailsDialog">
      <el-row class="text1">
        <span>发票编号：</span><span class="text2">{{detailTopList.invoiceSequence}}</span>
      </el-row>
      <el-row>
        <span class="text1">开票信息</span>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span class="text3">发票抬头：</span><span class="text4">{{detailTopList.buyerName}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">发票类型：</span><span class="text4">{{detailTopList.invoiceTypeDesc}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">开票人账号：</span><span class="text4">{{detailTopList.userCode}}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span class="text3">税号：</span><span class="text4">{{detailTopList.buyerTaxNum}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">开户行：</span><span class="text4">{{detailTopList.c_bankAccount}}</span>
        </el-col>
        <el-col :span="8">
          <span class="text3">注册地址：</span><span class="text4">{{detailTopList.c_address}}</span>
        </el-col>
      </el-row>
      <el-row>
        <span class="text3">开票金额：</span><span class="text5">{{detailTopList.money}}元</span>
      </el-row>
      <el-row>
        <span class="text3">开票备注：</span><span class="text4">{{detailTopList.c_remark}}</span>
      </el-row>
      <el-row>
        <span class="text3">申请备注：</span><span class="text4">{{detailTopList.applyRemark}}</span>
      </el-row>
      <el-row class="text1">
        <span>开票订单</span>
      </el-row>
      <!-- 订单内容 -->
      <el-row class="tableContent">
        <dt-table :tableColumnList="dialogTableColumnList"
                  :data="detailsDataList"
                  :map="dialogListMap"
                  :tableConfig='dialogTableConfig'
                  :paginationConfig='dialogPaginationConfig'
                  @getList="dialogGetList"></dt-table>
      </el-row>
      <div style="display:flex;justify-content:center">
        <el-row type="flex"
                justify="space-around"
                style="width:30%;margin-left:-4%">
          <el-col :span="2">
            <el-button type="primary"
                       @click="handleClickCheck('1')">同 意</el-button>
          </el-col>
          <el-col :span="2">
            <el-button type="primary"
                       @click="handleClickCheck('2')">驳 回</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
    <!-- 审核备注 -->
    <el-dialog class="dialog"
               title="审核备注"
               append-to-body
               width="30%"
               :visible.sync="remarkDialog">
      <el-input v-model="checkRemark"
                class="dt-form-width"
                placeholder="请输入"
                type="textarea"></el-input>
      <div>
        <el-row type="flex"
                justify="end">
          <el-col :span="4">
            <el-button type="primary"
                       @click="handleClickConfirm">确认</el-button>
          </el-col>
          <el-col :span="4">
            <el-button type="primary"
                       @click="remarkDialog=false">返回</el-button>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { dtTable },
  data () {
    //这里存放数据
    return {
      listMap: { pageNum: 1, pageSize: 15, },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableColumnList: [
        {
          prop: 'invoiceSequence',
          label: '发票编号',
        },
        {
          prop: 'money',
          label: '发票金额（元）',
        },
        {
          prop: 'invoiceTypeDesc',
          label: '开票类型',
        },
        {
          prop: 'invoiceDate',
          label: '开票时间',
        },
        {
          prop: 'type',
          label: '发票类型',
        },
        {
          prop: 'applyTime',
          label: '申请时间',
        },
        {
          prop: 'applyRemark',
          label: '申请备注',
        },
        {
          prop: 'statusDesc',
          label: '申请状态',
        },
      ],
      tableList: { list: [], },
      searchForm: {},
      pageNum: 1,//分页
      pageSize: 15,//分页
      // 弹窗表头
      dialogTableColumnList: [
        {
          prop: 'orderSequence',
          label: '支付流水号',
        },
        {
          prop: 'paymentTime',
          label: '支付时间',
        },
        {
          prop: 'payMethod',
          label: '支付渠道',
        },
      ],
      dialogListMap: { pageNum: 1, pageSize: 5, },
      dialogTableConfig: { border: true },
      dialogPaginationConfig: { layout: 'prev, pager, next,jumper', total: 0 },
      detailsDataList: { list: [] },
      invoiceSequence: '', // 发票编号
      dialogPagesize: 5,
      dialogPageNum: 1,
      // 日历快捷
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      detailsDialog: false,//详情弹窗显示
      detailTopList: {},//详情上半部分表单
      time: null,
      typeList: [],//发票类型
      statusList: [],// 申请状态
      remarkDialog: false,
      remarkstatus: "",
      checkRemark: "",
      id: ""
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryInvoiceType()
    this.queryStatusList()
    this.queryfinInvoiceRecord()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 审核
    handleClickCheck (val) {
      this.remarkstatus = val
      this.checkRemark = ''
      this.remarkDialog = true
    },
    // 审核确认
    handleClickConfirm () {
      let info = {
        id: this.id,
        status: this.remarkstatus,
        checkRemark: this.checkRemark
      }
      this.$reInvoiceRecord.checkReInvoice(info).then(() => {
        this.$message({ message: '审核成功', type: 'success' })
        this.remarkDialog = false
        this.detailsDialog = false
      })
    },
    // 列表查询
    queryfinInvoiceRecord () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$reInvoiceRecord.queryReInvoiceRecordList(this.searchForm).then(res => {
        this.tableList.list = res.resultEntity.list
        this.paginationConfig.total = res.resultEntity.total
      })
    },
    // 分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryfinInvoiceRecord()
    },
    //查询
    onSubmit () {
      this.pageNum = 1;
      this.listMap.pageNum = 1
      if (this.time) {
        this.searchForm.startTime = this.time[0]
        this.searchForm.endTime = this.time[1]
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.queryfinInvoiceRecord()
    },
    //重置
    resetForm () {
      this.searchForm = {}
      this.time = []
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.queryfinInvoiceRecord()
    },
    //弹窗表格分页
    dialogGetList (value) {
      this.dialogPageNum = value.pageNum
      this.queryDialogTable()
    },
    // 点击详情
    handleClickDetails (row) {
      this.invoiceSequence = ''
      this.id = ''
      this.detailTopList = row
      this.invoiceSequence = row.invoiceSequence
      this.id = row.id
      this.detailsDialog = true
      this.queryDialogTable()
    },
    // 详情中的表格渲染
    queryDialogTable () {
      let info = {
        pageNum: this.dialogPageNum,
        pageSize: this.dialogPagesize,
        invoiceSequence: this.invoiceSequence
      }
      this.$finInvoiceRecord.getPaymentRecordByInvoice(info).then(res => {
        this.detailsDataList.list = res.resultEntity.list
        this.dialogPaginationConfig.total = res.resultEntity.total
      })
    },
    // 查询发票类型
    queryInvoiceType () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: '4324HJKH31J123TBH1234L12K31341',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.typeList = res.resultEntity
      })
    },
    // 查看申请状态
    queryStatusList () {
      let info = {
        columnName: ['value', 'name'],
        tableName: 'tb_dict',
        whereStr: [
          {
            colName: 'parent_id',
            value: 'F8898BDD4BCF4W12ABA91DB0DEF',
          },
        ],
      }
      this.$queryDict.queryDict(info).then(res => {
        this.statusList = res.resultEntity
      })
    }
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
$topSelectHeight: 135px;
.mainbody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    min-height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    // min-height: calc(100% - #{$topSelectHeight} - 16px);
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      line-height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}
.dt-button-box {
  display: flex;
  justify-content: flex-end;
  .Fbutton {
    margin-left: 17px;
  }
}
.dt-width {
  width: 63%;
}
.previewImgClass {
  height: 100%;
  width: 100%;
}
.dialog .el-row {
  padding-top: 12px;
}
.text1 {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #f8fdff;
}
.text2 {
  font-weight: bold;
}
.text3 {
  color: #70aee8;
}
.text4 {
  color: #bae8ff;
}
.text5 {
  font-size: 24px;
  color: #e07d26;
}
</style>